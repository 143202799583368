<template>
	<div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">

		<Snackbar ref="snackbar"/>

		<v-dialog v-model="dialog.filter" scrollable persistent max-width="550px">
			<v-card class="border-radius box-shadow">
        <v-card-title class="body-2 text-first d-flex mb-4" style="background-color: #F05326;">
          <span class="white--text">
            Filter Data Users
          </span>
        </v-card-title>
				<v-card-text class="px-5" style="height: 500px;">
						<v-text-field
							outlined
							dense
							flat
							:hide-details="true"
							placeholder="Cari Users berdasarkan email atau nama"
							autocomplete="off"
							class="mb-4"
							v-model="$store.state.filter.email"
							color="#F05326"
							@click:clear="$store.state.filter.email = '';">
						</v-text-field>

						<v-text-field
							outlined
							dense
							flat
							:hide-details="true"
							placeholder="Cari data berdasarkan kode licensi"
							autocomplete="off"
							class="mt-3 mb-3"
							v-model="$store.state.filter.license"
							color="#F05326"
							@click:clear="$store.state.filter.license = '';">
						</v-text-field>

						<v-select
							:items="[{text: 'Email', value: 'email'}, {text: 'Google', value: 'google'}, {text: 'Facebook', value:'facebook'}, {text: 'Apple', value:'apple'}]"
							v-model="$store.state.filter.provider"
							item-text="text"
							item-value="value"
							color="#F05326"
							outlined
							solo
							filled
							dense
							flat
							label="Login provider">
						</v-select>

						<v-select
							:items="[{text: 'Ya', value: '1'}, {text: 'Tidak', value: '0'}]"
							v-model="$store.state.filter.has_phone"
							item-text="text"
							item-value="value"
							color="#F05326"
							outlined
							solo
							filled
							dense
							flat
							label="User memiliki nomor handphone">
						</v-select>

						<VueCtkDateTimePicker
							color="#F05326"
							label="Tanggal Registrasi"
							v-model="$store.state.filterdate.register"
							:range="true"
							:noClearButton="true"
							:auto-close="true"
							format="YYYY-MM-DD"
							formatted="LL"
							locale="id"
							id="users_filter"
							:custom-shortcuts="custome_shortcuts"
						/>

						<VueCtkDateTimePicker
							color="#F05326"
							label="Tanggal terakhir aktif"
							class="my-3"
							v-model="$store.state.filterdate.last_login"
							:range="true"
							:noClearButton="true"
							:auto-close="true"
							format="YYYY-MM-DD"
							formatted="LL"
							locale="id"
							id="users_filter"
							:custom-shortcuts="custome_shortcuts"
						/>

						<v-select
							:items="[{text: 'Ya', value: '1'}, {text: 'Tidak', value: '0'}]"
							v-model="$store.state.filter.has_subscribed_before"
							item-text="text"
							item-value="value"
							color="#F05326"
							hint="Apakah user sudah pernah berlangganan sebelumnya"
							persistent-hint
							outlined
							solo
							filled
							dense
							flat
							label="Sudah pernah berlangganan">
						</v-select>

						<v-select
							:items="[{text: 'Ya', value: '1'}, {text: 'Tidak', value: '0'}]"
							v-model="$store.state.filter.subscription"
							item-text="text"
							item-value="value"
							color="#F05326"
							class="mt-3 mb-0"
							hint="Apakah sekarang user sedang berlangganan"
							persistent-hint
							outlined
							solo
							filled
							dense
							flat
							label="Sedang berlangganan?">
						</v-select>

						<v-select
							:items="[{text: 'Redeem', value: 'redeem'}, {text: 'Subscribe Apple', value: 'subscribe_apple'}, {text: 'Midtrans Gopay', value:'midtrans_gopay'}, {text: 'Midtrans Shopeepay', value:'midtrans_shopeepay'}, {text: 'Xendit Shopeepay', value: 'xendit_shopeepay'}]"
							v-model="$store.state.filter.source_subscription"
							item-text="text"
							item-value="value"
							color="#F05326"
							hint="Hanya berlaku jika sedang berlangganan"
							:disabled="$store.state.filter.subscription == '0' || !$store.state.filter.subscription"
							persistent-hint
							outlined
							solo
							filled
							dense
							flat
							label="Metode berlangganan">
						</v-select>

						<VueCtkDateTimePicker
							color="#F05326"
							label="Tanggal Berlangganan"
							v-model="$store.state.filterdate.subscribe"
							:disabled="$store.state.filter.subscription == '0' || !$store.state.filter.subscription"
							:range="true"
							:noClearButton="true"
							:auto-close="true"
							format="YYYY-MM-DD"
							formatted="LL"
							locale="id"
							id="users_filter"
							:custom-shortcuts="custome_shortcuts"
						/>
						<p class="caption ml-3">Hanya berlaku jika sedang berlangganan</p>

						<VueCtkDateTimePicker
							color="#F05326"
							label="Tanggal kadaluarsa"
							v-model="$store.state.filterdate.expired"
							:disabled="$store.state.filter.subscription == '0' || !$store.state.filter.subscription"
							:range="true"
							:noClearButton="true"
							:auto-close="true"
							format="YYYY-MM-DD"
							formatted="LL"
							locale="id"
							id="users_filter"
							:custom-shortcuts="custome_shortcuts"
						/>
						<p class="caption ml-3">Hanya berlaku jika sedang berlangganan</p>

						<v-select
							:items="[
								{text: 'Tanggal Registrasi (Terbaru)', value: 'new_register'},
								{text: 'Tanggal Registrasi (Terlama)', value:'old_register'}, 
								{text: 'Tanggal Update (Terbaru)', value:'new_update'}, 
								{text: 'Tanggal Update (Terlama)', value:'old_update'},
								{text: 'Tanggal Berlangganan (Terbaru)', value:'new_subscription'},
								{text: 'Tanggal Berlangganan (Terlama)', value:'old_subscription'},
								{text: 'Tanggal Kadaluarsa (Terbaru)', value:'new_expired'},
								{text: 'Tanggal Kadaluarsa (Terlama)', value:'old_expired'},
								]"
							v-model="sort"
							@change="changeDataSort()"
							item-text="text"
							item-value="value"
							color="#F05326"
							outlined
							solo
							filled
							dense
							flat
							label="Sort">
						</v-select>

				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="teal"
						class="white--text ml-3"
						depressed>
						<v-icon left>mdi-download</v-icon>
						<vue-excel-xlsx
							:data="users.list"
							:columns="columnsExcel"
							:file-name="'Data User'"
							:file-type="'xlsx'"
							:sheet-name="'Data User'">
							Export Excel
						</vue-excel-xlsx>
					</v-btn>
					<v-btn elevation="0" class="ml-3 text-capitalize white--text" color="red" @click="$store.state.filter = {}; $store.state.filterdate = {}; dialog.filter = false;">
						Tutup
					</v-btn>
					<v-btn elevation="0" class="ml-3 text-capitalize" @click="$store.state.filter = {}; $store.state.filterdate = {}; fetch() ">
						<!-- dialog.status = 'reset'; fetch() -->
						Reset
					</v-btn>
					<v-btn elevation="0" class="text-capitalize" color="#F05326" dark @click="fetch()">
						Filter
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<div class="mt-5">
			<v-row justify="space-between" class="mt-2">
				<v-col cols="4">
					<div class="title black--text mb-2">Daftar Users</div>
				</v-col>
				<v-col cols="8" class="text-right">
          <div class="d-flex float-right">
            <v-btn 
              depressed
              @click="dialog.filter = true"
              elevation="0"
              color="#F5F5F5" 
              class="black--text text-capitalize ml-5">
              <v-icon left>mdi-filter-variant</v-icon>
              <span>Filter</span>
            </v-btn>
          </div>
        </v-col>
				<!-- <v-col cols="4">
					<div>
						<v-text-field
							outlined
							dense
							flat
							:hide-details="true"
							placeholder="Cari Users . . ."
							autocomplete="off"
							v-model="$store.state.filter.searching"
							color="#F05326"
							@click:clear="$store.state.filter.searching = '';"
							prepend-inner-icon="mdi-magnify">
						</v-text-field>
					</div>
				</v-col>
				<v-col class="mr-2">
					<VueCtkDateTimePicker
						color="#F05326"
						label="Pilih Tanggal Registrasi"
						v-model="$store.state.filter.filterdate"
						:range="true"
						:noClearButton="true"
						:auto-close="true"
						format="YYYY-MM-DD"
						formatted="LL"
						locale="id"
						id="users_filter"
						:custom-shortcuts="custome_shortcuts"
					/>
				</v-col>
				<v-col class="mr-2">
					<v-select
						:items="[{text: 'Subscribed', value: '1'}, {text: 'Not Subscribed', value: '0'}]"
						v-model="$store.state.filter.subscription"
						item-text="text"
						item-value="value"
						color="#F05326"
						outlined
						solo
						filled
						dense
						flat
						label="Tipe Subscribe">
					</v-select>
				</v-col>
				<v-col class="mr-2">
					<v-select
						:items="[{text: 'Email', value: 'email'}, {text: 'Google', value: 'google'}, {text: 'Facebook', value:'facebook'}, {text: 'Apple', value:'apple'}]"
						v-model="$store.state.filter.provider"
						item-text="text"
						item-value="value"
						color="#F05326"
						outlined
						solo
						filled
						dense
						flat
						label="Provider">
					</v-select>
				</v-col>
				<v-col>
					<v-select
						:items="[{text: 'Nama', value: 'fullname'},{text: 'Tanggal Dibuat', value:'created_at'}, {text: 'Tanggal Diubah', value:'updated_at'}, {text: 'Subscription', value:'subscription'}]"
						v-model="$store.state.filter.sort"
						item-text="text"
						item-value="value"
						color="#F05326"
						outlined
						solo
						filled
						dense
						flat
						label="Sort">
					</v-select>
				</v-col> -->
			</v-row>
			<!-- <v-row no-gutters class="py-2">
				<v-col>
					<v-btn elevation="0" color="#F05326" dark @click="fetch()">
						Search
					</v-btn>
					<v-btn elevation="0" class="ml-3" @click="$store.state.filter = {}; fetch()">
						Reset
					</v-btn>
					<v-btn
						color="teal"
						class="white--text ml-3"
						depressed>
						<v-icon left>mdi-download</v-icon>
						<vue-excel-xlsx
							:data="users.list"
							:columns="columnsExcel"
							:file-name="'Data User'"
							:file-type="'xlsx'"
							:sheet-name="'Data User'">
							Export Excel
						</vue-excel-xlsx>
					</v-btn>
				</v-col>
			</v-row> -->

			<div class="mt-3 mb-8">
				<v-skeleton-loader
					class="ma-auto"
					:loading="$store.state.process.run"
					:tile="false"
					transition="scale-transition"
					type="table-tbody">
					<v-data-table
						hide-default-footer
						fixed-header
						:loading="$store.state.process.run"
						:headers="headers"
						:items="users.list"
						:items-per-page.sync="users.limit"
						:page.sync="users.page"
						:server-items-length="users.total"
						item-key="id"
						:footer-props="{
							itemsPerPageText : 'Data per halaman',
							'items-per-page-options': [5,10, 20, 50, 100, 200, 350]
						}">
						<template slot="footer" slot-scope="{ props }">
							<Pagination 
								@reload="fetch(page)"
								@changeLimit="limit = $event; fetch()" 
								@changePage="page = $event; fetch($event)" 
								:model="props"/>
						</template>
						<template v-slot:[`item.child_name`]="{ item }">
							<v-list-item class="pa-0">
								<v-list-item-avatar v-if="item.picture" style="height: 42px; min-width: 42px; width: 42px; border: 3px solid rgb(255, 255, 255);background-color: white" size="40">
									<v-img :src="item.picture">
										<template v-slot:placeholder>
											<v-row
												class="fill-height ma-0"
												align="center"
												justify="center">
												<v-progress-circular
													indeterminate
													color="#d31145">
												</v-progress-circular>
											</v-row>
										</template>
									</v-img>
								</v-list-item-avatar>

								<v-list-item-avatar v-else style="height: 42px; min-width: 42px; width: 42px; border: 3px solid rgb(255, 255, 255);background-color: white" size="40">
									<v-img :src="require('@/assets/img/profile_picture.png')">
										<template v-slot:placeholder>
											<v-row
												class="fill-height ma-0"
												align="center"
												justify="center">
												<v-progress-circular
													indeterminate
													color="#d31145">
												</v-progress-circular>
											</v-row>
										</template>
									</v-img>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title class="caption font-weight-bold text-second mb-1">
										{{ item.child_name }}
									</v-list-item-title>
									<v-list-item-subtitle class="caption text-second mb-1">
										{{ item.email }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</template>
						<template v-slot:[`item.progress_detail`]="{ item }">
							<v-btn
								elevation="0"
								color="#F0532630"
								class="text-capitalize"
								style="color: #F05326"
								small
								@click="detailProgress(item)">
								<v-icon size="20" left>mdi-chart-box</v-icon>
								Detail Progres
							</v-btn>
						</template>
						<template v-slot:[`item.gender`]="{ item }">
							{{ item.gender == "F" ? "Perempuan" : "Laki-laki" }}
						</template>
						<template v-slot:[`item.age`]="{ item }">
							{{ item.age }}th
						</template>
						<template v-slot:[`item.status`]="{ item }">
							<div v-if="item.subscription.is_subscribed == true" class="font-weight-bold teal--text">
								Berlangganan
							</div>
							<div v-else class="font-weight-bold red--text">
								Belum berlangganan
							</div>
						</template>
						<template v-slot:[`item.history_subscription`]="{ item }">
							<v-btn
								elevation="0"
								color="#BBDEFB"
								class="text-capitalize"
								style="color: #2962FF"
								small
								@click="historySubscription(item)">
								Lihat History
							</v-btn>
						</template>
						<template v-slot:[`item.last_login`]="{ item }">
							{{ item.last_login | datetime }}
						</template>
						<template v-slot:[`item.action`]="{ item }">
							<v-menu
								bottom
								left>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										icon
										v-bind="attrs"
										v-on="on"
									>
										<v-icon>mdi-dots-vertical</v-icon>
									</v-btn>
								</template>

								<v-list>
									<v-list-item @click="detailUser(item, 'detail')">
										<v-list-item-icon>
											<v-icon>mdi-clipboard-text-outline</v-icon>
										</v-list-item-icon>
										<v-list-item-title>Detail User</v-list-item-title>
									</v-list-item>

									<v-list-item @click="detailUser(item, 'update')">
										<v-list-item-icon>
											<v-icon>mdi-pencil-outline</v-icon>
										</v-list-item-icon>
										<v-list-item-title>Ubah User</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>
					</v-data-table>
				</v-skeleton-loader>
			</div>

			<progress-history-modal
				v-model="dialog.history_subscription"
				:items="subsList && Array.isArray(subsList.list) ? subsList.list : []"
			/>

		</div>

	</div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import Pagination from '@/components/Pagination.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import ProgressHistoryModal from '../../../components/progress/progress-history-modal.vue';
import moment from 'moment'
export default {
	components: { Pagination, Breadcrumbs, Snackbar, ProgressHistoryModal },
	data() {
		return {
			isShowDetail: false,
			sort: "",
			users: {},
			subsList: {},
			limit: 10,
			page: 1,
			selected: {
				pagination: 0
			},
			custome_shortcuts: [
				{ key: 'today', label: 'Hari ini', value: 'day' },
				{ key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
				{ key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
				{ key: 'last7Days', label: '7 hari terakhir', value: 7 },
				{ key: 'last30Days', label: '30 hari terakhir', value: 30 },
				{ key: 'thisMonth', label: 'Bulan ini', value: 'month' },
				{ key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
			],
			headers: [
				{ text: 'Nama Lengkap',align: 'start',value: 'child_name',sortable: false, width: 200},
				{ text: 'Detail Progress',align: 'start',value: 'progress_detail',sortable: false },
				{ text: 'Jenis Kelamin',align: 'start',value: 'gender',sortable: false },
				{ text: 'Umur',align: 'start',value: 'age',sortable: false },
				{ text: 'Status',align: 'center',value: 'status',sortable: false },
				{ text: 'Subscription History',align: 'center',value: 'history_subscription',sortable: false },
				{ text: 'Terakhir Akses',align: 'center',value: 'last_login',sortable: false },
				{ text: 'Aksi',align: 'center',value: 'action',sortable: false }
			],
			process: {
				run: false
			},
			dialog: {
				history_subscription: false,
				filter: false,
				status: ''
			}
		}
	}, 
	watch: {
		'selected.pagination': function(val) {
			this.fetch(val+1)
		},
	},
	computed: {
		filterMinRegister() {
			return this.$store.state.filterdate.register ? moment(this.$store.state.filterdate.register.start).subtract(1, 'days').format('YYYY-MM-DD') + ' 16:59:59' : ''
		},
		filterMaxRegister() {
			return this.$store.state.filterdate.register ? moment(this.$store.state.filterdate.register.end).format('YYYY-MM-DD')  + ' 17:00:00' : ''
		},
		filterMinSubscription() {
			return this.$store.state.filterdate.subscribe ? moment(this.$store.state.filterdate.subscribe.start).subtract(1, 'days').format('YYYY-MM-DD') + ' 16:59:59' : ''
		},
		filterMaxSubscription() {
			return this.$store.state.filterdate.subscribe ? moment(this.$store.state.filterdate.subscribe.end).format('YYYY-MM-DD') + ' 17:00:00' : ''
		},
    filterMinLastLogin() {
			return this.$store.state.filterdate.last_login ? moment(this.$store.state.filterdate.last_login.start).subtract(1, 'days').format('YYYY-MM-DD') + ' 16:59:59' : ''
		},
		filterMaxLastLogin() {
			return this.$store.state.filterdate.last_login ? moment(this.$store.state.filterdate.last_login.end).format('YYYY-MM-DD')  + ' 17:00:00' : ''
		},
		filterMinExpired() {
			return this.$store.state.filterdate.expired ? moment(this.$store.state.filterdate.expired.start).subtract(1, 'days').format('YYYY-MM-DD') + ' 16:59:59' : ''
		},
		filterMaxExpired() {
			return this.$store.state.filterdate.expired ? moment(this.$store.state.filterdate.expired.end).format('YYYY-MM-DD') + ' 17:00:00' : ''
		},
		columnsExcel() {
			return [
				{
					label: "ID",
					field: "id",
				},
				{
					label: "Nama",
					field: "child_name",
				},
				{
					label: "Username",
					field: "username",
				},
				{
					label: "Email",
					field: "email",
				},
				{
					label: "Phone",
					field: "phone",
				},
				{
					label: "Jenis Kelamin",
					field: "gender",
				},
				{
					label: "Provider",
					field: "provider",
				},
				{
					label: "Alamat",
					field: "address",
				},
				{
					label: "Umur",
					field: "age",
				},
				{
					label: "Tanggal Lahir",
					field: "birthday",
				},
				{
					label: "Tempat Lahir",
					field: "birth_place",
				},
				{
					label: "Tanggal Daftar",
					field: "created_at",
					dataFormat: (value) => {
						return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
					}
				},
				{
					label: "Akses Terakhir",
					field: "last_login",
					dataFormat: (value) => {
						return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
					}
				},
				{
					label: "Status Berlangganan",
					field: "subscription.is_subscribed",
					dataFormat: (value) => {
						return value == true ? '✅' : '❌'
					}
				},
				{
					label: "Nama Langganan",
					field: "subscription.package.title",
				},
				{
					label: "Tanggal Berlangganan",
					field: "subscription.package.subscribe_at",
					dataFormat: (value) => {
						return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
					}
				},
				{
					label: "Tanggal Habis",
					field: "subscription.package.expired_at",
					dataFormat: (value) => {
						return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
					}
				},
				{
					label: "License",
					field: "subsList",
					dataFormat: this.LicenseFormat,
				}
			]
		},
	},
	mounted () {
		this.fetch()
	},
	methods: {
		changePage (event) {
			if (event === undefined) {
				this.selected.pagination = 0
			} 
		},
		changeDataSort() {

			switch (this.sort) {
				case 'new_register' :
					this.$store.state.filter.sort = 'created_at'
					this.$store.state.filter.dir = 'desc'
					break;
				case 'old_register' :
					this.$store.state.filter.sort = 'created_at'
					this.$store.state.filter.dir = 'asc'
					break;
				case 'new_update' :
					this.$store.state.filter.sort = 'updated_at'
					this.$store.state.filter.dir = 'desc'
					break;
				case 'old_update' :
					this.$store.state.filter.sort = 'updated_at'
					this.$store.state.filter.dir = 'asc'
					break;
				case 'new_subscription' :
					this.$store.state.filter.sort = 'subscription.subscribed_at'
					this.$store.state.filter.dir = 'desc'
					break;
				case 'old_subscription' :
					this.$store.state.filter.sort = 'subscription.subscribed_at'
					this.$store.state.filter.dir = 'asc'
					break;
				case 'new_expired' :
					this.$store.state.filter.sort = 'subscription.expired_at'
					this.$store.state.filter.dir = 'desc'
					break;
				case 'old_expired' :
					this.$store.state.filter.sort = 'subscription.expired_at'
					this.$store.state.filter.dir = 'asc'
					break;
			}
		},
		async fetch(paging){
			let _self = this;
			_self.process.run = true
			this.$store.state.process.run = true
			// if(this.dialog.status == 'reset') {
      //   this.dialog.filter = true
      // }else {
      //   this.dialog.filter = false
      // }
			this.dialog.filter = false
			let params = {}
      if (this.$store.state.filter.subscription == '1') {
        params = {
          limit: this.limit,
          page: paging,
          sort: this.$store.state.filter.sort,
				  dir: this.$store.state.filter.dir,
          q: this.$store.state.filter.email,
          license: this.$store.state.filter.license,
          provider: this.$store.state.filter.provider,
          phone: this.$store.state.filter.phone,
          has_phone: this.$store.state.filter.has_phone,
          is_subscribed: this.$store.state.filter.subscription,
          has_subscribed_before: this.$store.state.filter.has_subscribed_before,
          subscription_source: this.$store.state.filter.source_subscription,
          min_register: this.filterMinRegister,
          max_register: this.filterMaxRegister,
          min_subscribe: this.filterMinSubscription,
          max_subscribe: this.filterMaxSubscription,
          min_last_login: this.filterMinLastLogin,
				  max_last_login: this.filterMaxLastLogin,
          min_expired: this.filterMinExpired,
          max_expired: this.filterMaxExpired,
        }
      }else {
        params = {
          limit: this.limit,
          page: paging,
          sort: this.$store.state.filter.sort,
				  dir: this.$store.state.filter.dir,
          q: this.$store.state.filter.email,
					license: this.$store.state.filter.license,
          provider: this.$store.state.filter.provider,
          phone: this.$store.state.filter.phone,
          has_phone: this.$store.state.filter.has_phone,
          is_subscribed: this.$store.state.filter.subscription,
          has_subscribed_before: this.$store.state.filter.has_subscribed_before,
          // subscription_source: this.$store.state.filter.source_subscription,
          min_register: this.filterMinRegister,
          max_register: this.filterMaxRegister,
          min_last_login: this.filterMinLastLogin,
				  max_last_login: this.filterMaxLastLogin,
          // min_subscribe: this.filterMinSubscription,
          // max_subscribe: this.filterMaxSubscription,
          // min_expired: this.filterMinExpired,
          // max_expired: this.filterMaxExpired,
        }
      }

      await get(`api/v1/member/list`,{
          params
      }).then(response => {
        let res = response.data
        this.users = res.data
        this.$store.state.process.run = false
        this.totalData = res.data.total
				// this.dialog.status = ''
      })
    },
    detailUser(item, status) {
      if (status == "update") {
        this.$router.push({ name: 'user-update', params: { id: item.id },  query: { ...this.$route.query } })
      }else {
        this.$router.push({ name: 'user-detail', params: { id: item.id },  query: { ...this.$route.query } })
      }
    },
    detailProgress(item) {
      this.$router.push({ name: 'progress-detail', params: { id: item.id }, query: { ...this.$route.query } })
    },
    async historySubscription(item) {
      this.dialog.history_subscription = true;
      await this.getSubsList({ email: item.email });
    },
    async getSubsList(data) {
      await get(`api/v1/subscription/history/list`, {
        params: {
          // user_id: data.user_id,
          email: data.email,
          limit: 100
        }
      }).then(response => {
        let res = response.data
        if (res.status == 200) {
          this.subsList = res.data
        }
      })
    }
  }
}
</script>

<style>

</style>